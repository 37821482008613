export default [
    {
      key: 'email',
      label: 'User'
    },
    {
      key: 'ip',
      label: 'Ip',
    },
    {
      key: 'start_login',
      label: 'Start Login',
    },
    {
      key: 'end_login',
      label: 'End Login',
    },
]