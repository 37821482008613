<template>
  <div>
       <header-slot>
            <template #actions>
                <b-row class="justify-content-end mr-1">
                    <b-button variant="outline-danger" :to="`/${routeModule}`"> Back </b-button>
                </b-row>
            </template>
       </header-slot>
       <template>
            <!-- First Row -->
            <b-row>
                <b-col cols="12">
                    <div class="mx-2 mb-2 mt-2">
                        <b-row>
                            <b-col
                                cols="12"
                                sm="6"
                                class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-start
                                "
                            >
                                <span class="text-muted">
                                Showing {{ startPage }} to {{ toPage }} of {{ totalRows }} entries
                                </span>
                            </b-col>
                            <!-- Pagination -->
                            <b-col
                                cols="12"
                                sm="6"
                                class="
                                d-flex
                                align-items-center
                                justify-content-center justify-content-sm-end
                                pagination_1
                                "
                            >
                                <slot name="pagination">
                                <b-pagination
                                    v-model="paginate.currentPage"
                                    :total-rows="totalRows"
                                    :per-page="paginate.perPage"
                                    first-number
                                    last-number
                                    class="mb-0 mt-1 mt-sm-0"
                                    prev-class="prev-item"
                                    next-class="next-item"
                                >
                                    <template #prev-text>
                                    <feather-icon icon="ChevronLeftIcon" size="18" />
                                    </template>
                                    <template #next-text>
                                    <feather-icon icon="ChevronRightIcon" size="18" />
                                    </template>
                                </b-pagination>
                                </slot>
                            </b-col>
                        </b-row>
                    </div>
                    <b-col
                        cols="12"
                        class="d-flex align-items-center justify-content-start mb-2"
                    >
                        <label>Show</label>
                        <v-select
                        v-model="paginate.perPage"
                        :options="[10, 25, 50, 100]"
                        :clearable="false"
                        class="per-page-selector d-inline-block mx-50"
                        @input="$refs.refSessionsTable.refresh()"
                        />
                        <label class="mr-2">entries</label>
                        <feather-icon
                            class="cursor-pointer"
                            icon="RefreshCcwIcon"
                            size="20"
                            @click="$refs.refSessionsTable.refresh()"
                        />
                    </b-col>
                    <b-table
                        ref="refSessionsTable"
                        class="position-relative"
                        :items="myProvider"
                        :fields="fields"
                        primary-key="id"
                        table-class="text-nowrap"
                        responsive="sm"
                        show-empty
                        no-provider-filtering
                        sticky-header="65vh"
                        :busy.sync="isBusy"
                        :current-page="paginate.currentPage"
                        :per-page="paginate.perPage"
                        small
                    >
                        <template #table-busy>
                            <div class="text-center text-primary my-2">
                                <b-spinner class="align-middle mr-1"/>
                                <strong>Loading ...</strong>
                            </div>
                        </template>
                        <template #cell(end_login)="data">
                            <span>{{data.value ? data.value : '-'}}</span>
                        </template>
                    </b-table>
                </b-col>
            </b-row>
        </template>
    </div>
</template>

<script>
import UsersService from '../../../views/users/services/users.service'
import dataFields from './fields.data'
import vSelect from 'vue-select'
export default {
    components: {
        vSelect
    },
    data() {
        return {
            fields: dataFields,
            isBusy: false,
            paginate: {
                currentPage: 1,
                perPage: 10,
            },
            startPage: null,
            toPage: null,
            totalRows: 0,
            items: []
        }
    },
    computed: {
        routeModule() {
            return this.$route.meta.route;
        }
    },
    methods: {
        async myProvider(ctx) {
            try {
                const params = {
                    id: this.$route.params.id,
                    perpage: ctx.perPage,
                }
                const data = await UsersService.searchSessionsUsers(params, ctx.currentPage)
                this.items = data.data.data
                this.startPage = data.data.from
                this.paginate.currentPage = data.data.current_page
                this.paginate.perPage = data.data.per_page
                this.totalRows = data.data.total
                this.toPage = data.data.to

                return this.items || []
            } catch (e) {
                this.showErrorSwal(e)
                return []
            }
        }
    }
}
</script>

<style>

</style>